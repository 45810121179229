import React from "react"
import { Link } from "gatsby"
import { kebabCase } from "lodash"
import { PostConfig } from "../config/config-data"
import ExternalScript from "./external-script"

const Posts = ({
  postsMain,
  postsSlug,
  postsBaseUrl,
  postsTo,
  postsName,
  postsGLTF,
  postsTags
}) => (
  
  <div id="posts_1662986460579" style={{width:"50vh", margin:"var(--Space_Size_Three)"}}>
    <ExternalScript />
    <div id="posts_1662986461728" className="Post_List_Card">
      <div id="posts_1662986463297" className="Post_List_Card_Header">
        <div id="posts_1662986464575" style={{height:"50vh"}}>
          <Link to={`/blog${postsSlug}`}>
            <model-viewer
              data-pin-no-hover="true" //This is for Pinterest
              style={{width:"100%", height:"50vh"}}
              alt="Your Gift Loading"
              loading="eager"
              src={postsGLTF}
              poster={`${postsBaseUrl}/assets/mv-poster.svg`}
            />
          </Link>
        </div>
        <div id="posts_1662986467703" className="Post_List_Card_Title"><Link to={`/blog${postsSlug}`}>Surprise for {postsName || postsTo}</Link></div>
      </div>
      {PostConfig.post_list_description &&
      <div id="posts_1662986469415" className="Post_List_Card_Container">
        <div id="posts_1662986470934" className="Normal_Text">{postsMain.length > 115 ? postsMain.substring(0, 115) + "..." : postsMain}</div>
      </div>
      }
      <div id="posts_1662986473094" style={{justifyContent:"space-between"}} className="Post_List_Card_Footer">
        {PostConfig.post_list_tag &&
        <div id="posts_1662986474112" className="Post_List_Tag_Container">
          {postsTags.map(tag => (
            <Link key={tag} style={{marginLeft:"0px"}} className="Custom_Badge" to={`/blog/tag/${kebabCase(tag)}-page-1`}>#{tag}</Link>
          ))}
        </div>
        }
        <Link to={`/blog${postsSlug}`} style={{marginLeft:"auto"}}>
          <button>{PostConfig.post_list_button_name}</button>
        </Link>
      </div>
    </div>
  </div>
)

export default Posts